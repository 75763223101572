import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { finalize, tap } from "rxjs/operators";
import { SelectOption } from "src/app/shared/interfaces/select-option.interface";
import { getUpdatedItems } from "src/app/shared/utils/utils";
import { PodiumJob } from "../interfaces/podium-job.interface";
import { PodiumOrder } from "../interfaces/podium-order.interface";
import { PodiumService } from "../services/podium.service";
import { LoadPodiumJobDetails, LoadPodiumOrders, ReloadPodiumOrders, RemoveHeader, RemoveReplacementHeader, SetHeader, SetReplacementHeader } from "./podium.actions";
import { PodiumStateModel } from "./podium.model";

@State<PodiumStateModel>({
	name: "podium",
	defaults: {
		loading: false,
		orders: null,
		filters: {
			date: '',
			unionId: null	
		},
		timeOptions: []
	}
})
@Injectable()
export class PodiumState {
	constructor(
		private podiumService: PodiumService
	) {}

	@Selector() static loading(state: PodiumStateModel) { return state.loading; }
	@Selector() static orders(state: PodiumStateModel) { return state.orders; }
	@Selector() static searchedUnionId(state: PodiumStateModel) { return state.filters.unionId; }
	@Selector() static searchedDate(state: PodiumStateModel) { return state.filters.date; }
	@Selector() static timeOptions(state: PodiumStateModel) { return state.timeOptions; }

	@Action(LoadPodiumOrders)
	loadPodiumOrders(ctx: StateContext<PodiumStateModel>, action: LoadPodiumOrders) {
		ctx.patchState({
			loading: true,
			orders: null,
			filters: {
				date: action.date,
				unionId: action.unionId,
				employerIds: action.employerIds,
				terminalIds: action.terminalIds,
				startTimes: action.startTimes
			}
		});

		return this.podiumService.getPodiumList(action.date, action.unionId, action.employerIds, action.terminalIds, action.startTimes).pipe(
			tap((response: any) => {
				const orders: PodiumOrder[] = response?.map((e) => {
					let order: PodiumOrder = { ...e };
					order.isLoaded = false;
					return order;
				});
				
				ctx.patchState({
					orders: orders,
					loading: false,	
					timeOptions: this.getTimeOptions(response, [...ctx.getState().timeOptions])
				});
			})
		);
	}

	private getTimeOptions(orders: PodiumOrder[], currenOptions: SelectOption[]): SelectOption[] {
		const timeOptions: SelectOption[] = currenOptions;
		orders.forEach((e) => {
			const exists: SelectOption = timeOptions.find(f => f.value === e.startTime);
			if (!exists) {
				timeOptions.push({
					value: e.startTime,
					text: e.startTime
				});
			}
		});
		return timeOptions.sort();
	}

	@Action(ReloadPodiumOrders)
	reloadPodiumOrders(ctx: StateContext<PodiumStateModel>) {
		const state = ctx.getState();
		ctx.dispatch(
			new LoadPodiumOrders(
				state.filters.date,
				state.filters.unionId,
				state.filters.employerIds,
				state.filters.terminalIds,
				state.filters.startTimes
			)
		);
	}

	@Action(LoadPodiumJobDetails)
	loadPodiumJobDetails(ctx: StateContext<PodiumStateModel>, action: LoadPodiumJobDetails) {
		ctx.patchState({ loading: true });
		return this.podiumService.getPodiumOrderJobDetails(action.laborOrderId, action.unionId, action.date).pipe(
			tap(
				(response: PodiumJob[]) => {
					const state = ctx.getState();
					let foundOrder = JSON.parse(
						JSON.stringify(state.orders.find(o => o.id == action.laborOrderId))
					);
					foundOrder.jobs = response.map((e) => {
						return e.jobList.map((job) => {
							return {
								id: job.id,
								name: job.name,
								numberOfWorkers: job.numberOfWorkers,
								header: job.header,
								replacementHeader: job.replacementHeader,
								dispatchOrderId: e.dispatchOrderId
							}
						})
					});
					foundOrder.isLoaded = true;
					ctx.patchState({
						orders: getUpdatedItems(foundOrder, state.orders),
						loading: false
					});
				}
			)
		);
	}
	
	@Action(SetHeader)
	setHeader(ctx: StateContext<PodiumStateModel>, action: SetHeader) {
		ctx.patchState({ loading: true });
		return this.podiumService.setHeader(action.assignment).pipe(
			finalize(
				() => {
					const state = ctx.getState();
					ctx.dispatch(new LoadPodiumJobDetails(action.laborOrderId, state.filters.unionId, state.filters.date));
				}
			)
		);
	}

	@Action(SetReplacementHeader)
	setReplacementHeader(ctx: StateContext<PodiumStateModel>, action: SetReplacementHeader) {
		ctx.patchState({ loading: true });
		return this.podiumService.setReplacementHeader(action.assignment).pipe(
			finalize(
				() => {
					const state = ctx.getState();
					ctx.dispatch(new LoadPodiumJobDetails(action.laborOrderId, state.filters.unionId, state.filters.date));
				}
			)
		);
	}

	@Action(RemoveHeader)
	removeHeader(ctx: StateContext<PodiumStateModel>, action: RemoveHeader) {
		ctx.patchState({ loading: true });
		return this.podiumService.removeHeader(action.jobAssignmentId).pipe(
			finalize(
				() => {
					const state = ctx.getState();
					ctx.dispatch(new LoadPodiumJobDetails(action.laborOrderId, state.filters.unionId, state.filters.date));
				}
			)
		);
	}

	@Action(RemoveReplacementHeader)
	removeReplacementHeader(ctx: StateContext<PodiumStateModel>, action: RemoveReplacementHeader) {
		ctx.patchState({ loading: true });
		return this.podiumService.removeReplacementHeader(action.jobAssignmentId).pipe(
			finalize(
				() => {
					const state = ctx.getState();
					ctx.dispatch(new LoadPodiumJobDetails(action.laborOrderId, state.filters.unionId, state.filters.date));
				}
			)
		);
	}

}