import { AssignWorker } from "src/app/core/interfaces/assign-worker.interfaces";

export class LoadPodiumOrders {
	static readonly type = "[Podium] Load Podidium Orders";
	constructor(
		public date: string,
		public unionId: number,
    public employerIds: number[],
    public terminalIds: string[],
    public startTimes: string[]
	) {}
}

export class ReloadPodiumOrders {
	static readonly type = "[Podium] Reload Podium Orders";
}

export class LoadPodiumJobDetails {
	static readonly type = "[Podium] Load Podium Job Details";
	constructor(
		public laborOrderId: string,
		public unionId: number,
		public date: string
	) {}
}

export class SetHeader {
	static readonly type = '[Podium] Set Header';
	constructor(
		public assignment: AssignWorker,
		public laborOrderId: string
	) {}
}

export class SetReplacementHeader {
	static readonly type = '[Podium] Set Replacement Header';
	constructor(
		public assignment,
		public laborOrderId: string
	) {}
}

export class RemoveHeader {
	static readonly type = '[Podium] Remove Header';
	constructor(
		public jobAssignmentId: string,
		public laborOrderId: string
	) {}
}

export class RemoveReplacementHeader {
	static readonly type = '[Podium] Remove Replacement Header';
	constructor(
		public jobAssignmentId: string,
		public laborOrderId: string
	) {}
}