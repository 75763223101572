import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { API_URL } from 'src/environments/settings';
import { AssignWorker } from '../../../core/interfaces/assign-worker.interfaces';

@Injectable({ providedIn: 'root' })
export class PodiumService {

  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiUrl = this.env.getValueTransferState(API_URL);
  }

  getPodiumList(date: string, unionId: number, employerIds: number[], terminalIds: string[], startTimes: string[]) {
    const params: HttpParams = new HttpParams({
      fromObject: {
        date,
        unionId,
        employerIds,
        terminalIds,
        startTimes
      }
    });
    return this.http.get(`${this.apiUrl}/app/laborOrder/sumarizePodiumList`, { params });
  }

  getPodiumOrderJobDetails(laborOrderId: string, unionId: number, date: string) {
    const params: HttpParams = new HttpParams({
      fromObject: {
        laborOrderId,
        unionId,
        date
      }
    });
    return this.http.get(`${this.apiUrl}/app/laborOrder/sumarizeJobDetailPodiumList`, { params });
  }

  setHeader(assignment: AssignWorker) {
    return this.http.put(`${this.apiUrl}/app/dispatchOrder/assignJobRequest`, [assignment]);
  }

  setReplacementHeader(payload) {
		return this.http.put(`${this.apiUrl}/app/dispatchOrder/replaceWorker`, payload);
	}

  removeHeader(jobAssignmentId: string) {
		return this.http.delete(`${this.apiUrl}/app/dispatchOrder/jobAssignment/${jobAssignmentId}`);
	}

	removeReplacementHeader(jobAssignmentId: string) {
		return this.http.delete(`${this.apiUrl}/app/dispatchOrder/cancelReplaceWorker/${jobAssignmentId}`);
	}
  
}