import { SelectOption } from "src/app/shared/interfaces/select-option.interface";
import { PodiumOrder } from "../interfaces/podium-order.interface";

export class PodiumStateModel {
	loading: boolean;
	orders: PodiumOrder[];
	filters: {
		date: string;
		unionId: number;
    employerIds?: number[];
    terminalIds?: string[];
    startTimes?: string[];
	};
	timeOptions: SelectOption[];
}